import { useParams } from "react-router-dom";
import OrderStatus from "./components/OrderStatus";

export default function OrderStatusWrapper() {
  let {orderId} = useParams();
  return (
  <>
    {orderId && < OrderStatus orderId={orderId} />}
  </>
  );
}

