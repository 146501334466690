import { useState } from "react";
import LoginForm from "./components/LoginForm";

export default function LoginWrapper() {
    const [user, setUser] = useState({});
    
    return (
      <div style={{backgroundColor:"#FFFFFF", height:"100vh"}}>
         <LoginForm user={user} setUser={setUser} />
      </div>
        );
  }