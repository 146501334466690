import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import OrderDetailWrapper from './OrderDetailWrapper';
import OrderListWrapper from './OrderListWrapper';
import OrderStatusWrapper from './OrderStatusWrapper';
import OrderStatusTrackingWrapper from './OrderTrackingWrapper';
import LoginWrapper from './LoginWrapper';
import ProtectedRoute from './ProtectedRoutes';
import OrderDetailByIdWrapper from './OrderDetailByIdWrapper';
import OrderDetailWrapperAdmin from './OrderDetailWrapperAdmin';

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute element={<OrderListWrapper />} />
  },
  {
    path: "/app/customorder",
    element: <ProtectedRoute element={<OrderListWrapper />} />,
  },
  {
    path: "/app/order/:orderId",
    element: <ProtectedRoute element={<div style={{ backgroundColor: "#F7F7F7" }}><OrderDetailWrapper /></div>} />,
  },
  {
    path: "/app/admin/order/:orderId/:token",
    element: <div style={{ backgroundColor: "#F7F7F7" }}><OrderDetailWrapperAdmin /></div>,
  },
  {
    path: "/app/order",
    element: <ProtectedRoute element={<div style={{ backgroundColor: "#F7F7F7" }}><OrderDetailByIdWrapper /></div>} />,
  },
  {
    path: "/app/order-status/:orderId",
    element: <div  style={{  backgroundColor: "#FFFFFF", minHeight:"80Vh" }}><OrderStatusWrapper /></div>,
  },
  {
    path: "/app/order-tracking",
    element: <div  style={{  backgroundColor: "#FFFFFF" }}><OrderStatusTrackingWrapper /></div>,
  },
  {
    path: "/login",
    element: <div  style={{  backgroundColor: "#FFFFFF" }}><LoginWrapper /></div>,
  },
]);

function App() {
  return (
    <div className="App" >
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
