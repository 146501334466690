import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { AppProvider, Spinner } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import './style.loader.css'; // Import your custom CSS file

export default function CustomSpinner({ loading = false }) {
    return (
        loading && <div className="spinner-container">
            <Spinner size="large" />
        </div>
  );
}