import {
  Box,
  Link,
  Text,
  Icon,
  Modal,
  TextField,
  BlockStack,
  Popover,
  Card,
  DatePicker,
  ActionList,
} from "@shopify/polaris";
import { useState, useCallback, useEffect, useRef } from 'react';
import "./styles.customorder.css";
import { CalendarIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";

export default function DueDateSectoionDetail({ order, task_status, id, shop, due_date, setOrderList, order_name, shipping_date  }) {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(order_name);
  const [dueDateVisible, setDueDateVisible] = useState(false);
  const [visible, setVisible] = useState(null);
  const [activePopover, setActivePopover] = useState(false);
  const dueDatePickerRef = useRef(null);
  const datePickerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = () => {
    if (value) {
      handleUpdateOrder({
        shop: shop,
        id: id,
        order_name: value,
      });
      const updatedTags = updateOrderTags(order.tags, order_name, value);
      handleUpdateOrderTags({
        shop: shop,
        id: id,
        tags: updatedTags,
      });
    }
    setIsEditing(false);
      
  };
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
const [{ dueMonth, dueYear }, setDueDate] = useState({
  dueMonth: selectedDueDate.getMonth(),
  dueYear: selectedDueDate.getFullYear(),
});
  
const [{ month, year }, setDate] = useState({
  month: selectedDate.getMonth(),
  year: selectedDate.getFullYear(),
});
  
function handleMonthChange(month, year) {
  setDate({ month, year });
}
function handleDateSelection({ end: newSelectedDate }) {
  setSelectedDate(newSelectedDate);
  setVisible(null);
}
  
  
function handleOnClose({ relatedTarget }) {
  setVisible(null);
}
  
const handleUpdateOrder = (data) => {
  api.put("/updateOrder",data).then((result) => {
    console.log(result.data);
  });
}
  
const handleUpdateOrderTags = (data) => {
  api.put("/updateOrderTags",data).then((result) => {
    console.log(result.data);
  });
}

  function handleDueDateOnClose({ relatedTarget }) {
    setDueDateVisible(false);
  }

  function handleInputValueChange() {
    console.log("handleInputValueChange");
  }

  function handleDueMonthChange(dueMonth, dueYear) {
    setDueDate({ dueMonth, dueYear });
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get the components of the date
    const day = date.toDateString().split(' ')[0];
    const month = date.toDateString().split(' ')[1];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();

    // Format the date as "Thu May 16 2024"
    const formattedDate = `${month} ${dayOfMonth} ${year}`;
    return formattedDate;
  }

  function updateOrderTags(orderTags, oldValue, newValue) {
    // Split the tags string into an array
    let tagsArray = orderTags.split(', ');
  
    // Filter out the old value
    tagsArray = tagsArray.filter(tag => tag !== oldValue);
  
    // Add the new value
    tagsArray.push(newValue);
  
    // Join the array back into a string
    return tagsArray.join(', ');
  }

  const taskList = [
    {
      title: "Proof Needed",
      color: "success",
    },
    {
      title: "Proof Added",
      color: "info",
    },
    {
      title: "Proof Rejected",
      color: "warning",
    },
    {
      title: "Drawing Needed",
      color: "critical",
    },
    {
      title: "Pending Approval",
      color: "attention",
    },
    {
      title: "In production",
      color: "warning",
    }
  ];

 
  return (
    <div className="due-date-container">
      <div className="item">
              <Text as="h3" tone="subdued" fontWeight="semibold" >
              Order Name
        </Text>
        {isEditing ? (
        <span>
          <TextField
            value={value}
            onChange={(e) => setValue(e)}
            onBlur={handleBlur}
            autoFocus
          />
        </span>
      ) : (
        <span className="pointer" onClick={() => setIsEditing(true)}>
          <Text as="h3" fontWeight="regular">
            {value ? value : 'Not Set'}
          </Text>
        </span>
      )}
          
        </div>
          <div className="item">
              <Text as="h3" tone="subdued" fontWeight="semibold" >
              Delivery Date  
          </Text>
          <div style={{ display:"grid", width:"100%" }}>
          <Popover
                ariaHaspopup="dialog"
                fluidContent={true}
          active={dueDateVisible}
          autofocusTarget="none"
          fullWidth
          preferInputActivator={false}
                preferredAlignment="center" // Optional: Adjusts alignment
                preferredPosition="mostSpace" // Optional: Adjusts position
          preventCloseOnChildOverlayClick
          onClose={handleDueDateOnClose}
          activator={
            <span className="pointer" onClick={() => setDueDateVisible(true)}><Text as="h3" fontWeight="regular" >
              {due_date?formatDate(due_date):"Not Set"}
            </Text>
            </span>
          }
        >
          <Card ref={dueDatePickerRef}  >
            <DatePicker
              month={dueMonth}
              year={dueYear}
              selected={due_date?new Date(due_date):null}
              onMonthChange={handleDueMonthChange}                    
              onChange={({ end: newSelectedDate }) => {
                const updatedOrderList = {...order, due_date: newSelectedDate.toISOString()};
                 
                setSelectedDueDate(newSelectedDate);
                setDueDateVisible(false);
                setOrderList(updatedOrderList);
                handleUpdateOrder({
                  "shop":shop,
                  "id":id,
                  "due_date": newSelectedDate.toISOString()
               })
              }}      
            />
                </Card>  
            </Popover>
            </div>
        </div>
        {order.current_status === "ACCEPTED" && <div className="item">
              <Text as="h3" tone="subdued" fontWeight="semibold" >
              Ship Date
          </Text>
          
          <div style={{ display:"grid", width:"100%" }}>
              <Popover
          preferredPosition="mostSpace"
          active={visible}
          autofocusTarget="none"
          preferredAlignment="left"
          fullWidth
          preferInputActivator={false}
          preventCloseOnChildOverlayClick
          onClose={handleOnClose}
          activator={
            <span className="pointer" onClick={() => setVisible(true)}><Text  as="h3" fontWeight="medium" >
            {shipping_date?formatDate(shipping_date):"Not Set"}
          </Text>
          </span>
          }
        >
          <Card ref={datePickerRef}>
            <DatePicker
              month={month}
              year={year}
              selected={shipping_date?new Date(shipping_date):null}
              onMonthChange={handleMonthChange}                    
              onChange={({ end: newSelectedDate }) => {
                const updatedOrderList = {...order, shipping_date: newSelectedDate.toISOString()};
                setSelectedDate(newSelectedDate);
                setOrderList(updatedOrderList);
                handleUpdateOrder({
                  "shop":shop,
                  "id":id,
                  "shipping_date": newSelectedDate.toISOString()
               })
              }}      
            />
          </Card>
        </Popover>
      
          </div>
          </div>}
        
        {/* <div style={{ width: "100%", display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"10px" }}>
              <Text as="h3" tone="subdued" fontWeight="semibold" >
              Task Status
          </Text>
          
          
        <span style={{ width:"70%" }} className="pointer"><Popover
          active={activePopover}
          activator={
            <span onClick={() => setActivePopover(true)}>
              { getBadgeByStatus(task_status)}
              </span>
          }
          onClose={() => setActivePopover(null)}
        >
          <ActionList
            items={taskList.map((badge) => ({
              content: (
                 getBadgeByStatus(badge.title,task_status===badge.title)
              ),
              onAction: () => {
                const updatedOrderList = {...order, task_status: badge.title };
                setOrderList(updatedOrderList);
                setActivePopover(null);
                handleUpdateOrder({
                  "shop": shop,
                  "id": id,
                  "task_status": badge.title
                })
              },
            }))}
          />
        </Popover></span>
        </div> */}
      </div>  
      
  );
}