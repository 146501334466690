import React, { useEffect, useState } from 'react';
import { Card, FormLayout, Page, TextField, Button } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import "./styles.statusproform.css";
import CustomSpinner from './Loader';
import api from '../axiosClient';
import { useNavigate } from "react-router-dom";

export default function LoginForm({user, setUser}) {
  const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (value) => setEmail(value);
  const handlePasswordChange = (value) => setPassword(value);
  const handleSubmit = async () => {
    setLoading(true);
    const data = {
        "email": email,
        "password": password
    }
    
      await handleUserLogin(data);
      setLoading(false);

  };

  const handleUserLogin = async (data) => {
    setError(false);
    await api.post(`/login`, { ...data }).then((response) => {
        console.log(response.data);
        if (response?.data) {
            setUser(response.data);
            localStorage.setItem('user', JSON.stringify(response.data));  
            setError(false);
            navigate('/');
        } else {
            setError(true);
            setUser({});
            localStorage.removeItem('user');  
        }
    }).catch((error) => {
        setError(true);
        setUser({});
        localStorage.removeItem('user');
    });
}

    
  return (
      <Page>
          <CustomSpinner loading={loading} />
      <div className="center-form">
        <Card sectioned>
          <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>Login</h2>
          <FormLayout>
            <TextField
              label="Email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              placeholder="email@example.com"
            />
            <TextField
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              type="password"
              placeholder="Password"
            />
            <div style={{ textAlign: 'center' }}>
                          <Button className="custom-button" onClick={handleSubmit}>Login</Button>
                      </div>
                      {error && <div className="error-text">User not found. <br /> Please check the email and password.</div>}
          </FormLayout>
        </Card>
      </div>
    </Page>
  );
}
