import { useParams } from "react-router-dom";
import OrderDetail from "./components/OrderDetail";
import { useEffect, useState } from "react";



export default function OrderDetailWrapper() {
  let { orderId } = useParams();
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  useEffect(() => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser && storedUser?.artist) {
        setUser(storedUser);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  }, []);
  console.log(id, shop);
  return (
  <>
    {(orderId && user?.shop) && <div style={{width:"100%", minHeight:"100vh", height:"100%", paddingBottom:"30px"}}>< OrderDetail orderId={orderId} shop={user.shop} /></div>}
  </>
  );
}

