import React, { useState } from 'react';
import { Viewer,Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';



const ModalExample = ({ fileUrl="https://proofer.s3.amazonaws.com/WYZf2vHZ3A5PIf6h6fElfNsipkFiN0MG-Order34716CuffStyle5-CUFSTY5-0872-_1.pdf" }) => {
    return (
        <>
            <div
            style={{
                backgroundColor: '#fff',
                 /* Take full size */
                height: '100%',
                width: '100%',

                /* Displayed on top of other elements */
                    zIndex: 9999,
                marginBottom: "20px"
            }}
        >
            <div
                style={{
                    flexGrow: 1,
                    overflow: 'auto',
                    height:"100%"
                }}
                >
                {/* <embed src="/proof2questionOrder34726CustomLVHeadstallBuckle.pdf" type="application/pdf" width="100%" height="100%" /> */}
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"><Viewer fileUrl={fileUrl} />
                </Worker>
            </div>
        </div>
        </>
    );
};

export default ModalExample;