import {
  Box,
  Icon,
  Modal,
  Text,
  TextField,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import "./styles.customorder.css";
import api from "../axiosClient";
import { EditIcon, XCircleIcon } from "@shopify/polaris-icons";

export default function OrderNotes({ id, shop, ordernotes, setOrderList }) {

  const [notes, setNotes] = useState(ordernotes);
  const [editNotes, setEditNotes] = useState(false);
  

 
  useEffect(() => { 
  }, []);

  useEffect(() => { 
    setNotes(ordernotes);
  }, [ordernotes]);

  const handleUpdateOrder = () => {
    const data = {
      "shop": shop,
      "id": id,
      "note": notes
    };
    api.post("/updateOrderDetails",data).then((result) => {
      setOrderList(result.data);
    });
  }

  const handleEditComment = () => {
    if (editNotes) {
      handleUpdateOrder();
    }
    setEditNotes(!editNotes);
  }


  return (
    <Box id="notes-box">
          <div className="column-header">
          <Text as="h6" tone="subdued" fontWeight="semibold" >
              Order Notes
        </Text>  
        <span onClick={() => setEditNotes(true)}><Icon source={EditIcon} tone="base" /></span>
            </div> 
            <div className="right-column-body top-padding-remove">
        <Text as="span" fontWeight="regular" >
          {notes}
        </Text>
      </div>  
      <Modal
          size="small"
          open={editNotes}
          onClose={() => setEditNotes(false)}
        title="Edit note"
        
          // primaryAction={{
          //   content: 'Save',
          //   onAction: handleUpdateOrder,  
            
          // }}
          primaryAction={{
            content: 'Save',
            onAction: handleUpdateOrder,
          }}
          secondaryActions={{
            content: 'Cancel',
            onAction: () => setEditNotes(false)
          }}
        >
        <Modal.Section>
        <div style={{ padding: "0px 0px", display: "inline-grid", textAlign: "left", gap: "10px", width:"100%", }}>
        
        <TextField  placeholder="Add a comment..."
                value={notes}
                onChange={setNotes}
                multiline={3}
                autoComplete="off" />
        <span>To comment on an order or mention a staff member, use Timeline instead</span>
      </div>
        </Modal.Section>
      </Modal>
          </Box>      
  );
}
            
