import {
  Box,
  Text,
  Collapsible,
  Badge,
  Thumbnail,
  Button,
  Divider,
  Link,
  Modal,
  Icon,
} from "@shopify/polaris";
import {CaretDownIcon,CaretUpIcon } from "@shopify/polaris-icons"
import { useState, useEffect, useCallback } from 'react';
import "./styles.customorder.css";
import { Lightbox } from "react-modal-image";

export default function ItemsDetail({ user, items, show=false }) {
  const [open, setOpen] = useState(show);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [link, setLink] = useState({});
  
  return (
    <div style={{ gap: "20px"}}>
    <div style={{ margin: "0px 0px 20px" }}>
    <Box id="order-items-box">
          <div className="right-column-body">
          <div onClick={()=> setOpen(!open)} style={{display:'flex', justifyContent:"space-between"}}><Text variant="headingSm" tone="subdued">Items in Order</Text><span style={{marginLeft:"5px"}}><Icon source={!open?CaretDownIcon:CaretUpIcon} tone="primary" /></span></div>  
      <Collapsible
      open={open}
      id="basic-collapsible"
      transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
      expandOnPrint
        >
          
          {items.map((item) => 
            <>
          <div className="item">
                <div className="product-img-container" onClick={() => {
                  toggleActive();
                  setLink({
                    name: item.name,
                    url:item.src || "https://www.hydroscand.se/media/catalog/product/placeholder/default/image-placeholder-base.png"
                  })
          }}>
                  <Thumbnail
                    source={item.src || "https://www.hydroscand.se/media/catalog/product/placeholder/default/image-placeholder-base.png"}
                    alt={item.name}
              />
              </div>
              <div style={{width:"100%"}}>
              <Text as="h4" tone="subdued" fontWeight="bold">
                    <div className="web-mobile" style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <span>{item.name}</span>
                      {user?.artist?.permissions?.admin && <span className="web-mobile" style={{ gap: "10px" }}>
                        <Text as="span" tone="subdued" fontWeight="bold" textDecorationLine="line-through">${(Number(item.price) / Number(item.quantity)).toFixed(2)}</Text>
                        <span>{`$${(Number(item.price) / Number(item.quantity) - Number(item.total_discount) / Number(item.quantity)).toFixed(2)}`}</span>
                        <span>x</span>
                        <span style={{ marginRight: "30px" }}>{`${item.quantity}`}</span>
                        <span>{`$${(Number(item.price) - Number(item.total_discount)).toFixed(2)}`}</span>
                      </span>}
                    </div>
                  </Text>   
                  <div style={{ paddingTop: "5px" }}>
                  <Text as="span" tone="subdued" >
                  SKU:
                  </Text> 
                  <Text as="span" tone="subdued" >
                  {` ${item.sku}`}
                  </Text> 
                  </div>
                  {item.properties.map((property) => (
                    property.value  && typeof property?.value === 'string') &&  <div style={{ paddingTop: "5px" }}>
                    {property?.value?.includes("http") ?
                        <a
                        onClick={(event) => {
                          event.preventDefault(); 
                          toggleActive();
                          setLink({
                            name: property.name,
                            url:property.value
                          })
                  }}
                          href={property.value} >
                        {`${property.name}`}
                      </a> : <>
                        <Text as="span" tone="subdued" fontWeight="bold" >
                          {`${property.name}: `}
                        </Text>
                        <Text as="span" tone="subdued" >
                          {property.value}
                        </Text> </>
                    }
                  </div>
                )}
            </div>
            </div>
            </>
          )}
        </Collapsible>
      </div>  
      
        </Box>       
      </div>
      
      { (active && link?.name) && <Lightbox
      medium={link?.url}
      large={link?.url}
      alt={link?.name}
      onClose={toggleActive}
    />
      }
    </div>  
  );
}
            
