import { useParams } from "react-router-dom";
import OrderStatusTracking from "./components/OrderStatusTracking";
import { useState } from "react";
import OrderStatusForm from "./components/StatusProForm";

export default function OrderStatusTrackingWrapper() {
  const [orderList, setOrderList] = useState([]);
  
  return (
    <div style={{height:"100vh"}}>
      {!orderList.id && <OrderStatusForm setOrderList={setOrderList} />}
      {orderList.id && <OrderStatusTracking orderList={orderList} setOrderList={setOrderList} />}
    </div>
  );
}

