import { useEffect, useState } from "react";
import CustomOrderList from "./components/OrderList";
import api from "./axiosClient";



export default function OrderListWrapper() {
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  useEffect(() => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser && storedUser?.artist) {
        setUser(storedUser);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
    //handleGetOrders(`/getAllOrders?shop=the-rowdy-rose-8522.myshopify.com&tag=REJECTED`);
  }, []);

  const handleGetOrders = (url = `/getAllOrders?shop=${shop}`) => {
    api.get(url).then((data) => {
      data.data.map(async item => {
        await api.put("/updateOrder", {
          "shop": "the-rowdy-rose-8522.myshopify.com",
          "id": item.id,
          "task_status": "Proof Rejected"
      }).then((result) => {
          console.log(result)
        }); 
      } )
    }).catch((error) => {
      console.log(error);
      
    });
  }
  
  return (
  <>
      {user?.shop && <CustomOrderList shop={user.shop} />}
  </>
  );
}

