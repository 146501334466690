import {
  Box,
  Text,
  Grid,
  Link,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import { CheckCircleIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import { getBadgeByStatus } from "../utlis";
import { useNavigate } from "react-router-dom";

export default function RelatedOrders({ email, id, shop, user }) {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => { 
    handleGetSettings();
  }, []);

  const handleGetSettings = () => {
    api.get(`/getOrdersByEmail?email=${email}`)
  .then((response) => {
    setOrders(response.data);
    
  })
  .catch((error) => {
    console.error("Error fetching settings:", error);
  });
  }

  const handleUpdateOrder = (data) => {
    api.post("/updateOrderDetails",data).then((result) => {
      if(result.data.artist)
      {
        
      }
    });
  }


  return (
    <Box id="artist-box">
          <div className="column-header">
          <Text as="h6" tone="subdued" fontWeight="semibold" >
          Related Orders
              </Text>  
            </div> 
            <div className="right-column-body">
        
        <Grid columns={{ xs: 12, sm: 12, md: 10, lg: 16, xl: 20 }} style={{ marginTop: "10px", width: "100%" }}>
            
            {orders.map((order) => 
              <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 3, lg:4, xl: 5 }}>
                
                <Link url={`/app/order?id=${order.id}&shop=${shop}`}>              
                    {getBadgeByStatus(order.name)}
                </Link>
                
            </Grid.Cell>)
            }
          </Grid>
        
            </div>  
          </Box>      
  );
}
            
