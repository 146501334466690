import {
  Box,
  Text,
  Icon,
  Button,
  Grid,
  Modal,
  DropZone,
  TextField
} from "@shopify/polaris";
import "./styles.customorder.css";
import { InfoIcon, XCircleIcon, AttachmentFilledIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import { useCallback, useEffect, useState } from "react";


export default function OrderProofWaitingBanner({  shop, id, order, setOrderList, user, setLoading }) {
  const [proofFile, setProofFile] = useState([]);
  const [comment, setComment] = useState('');
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  useEffect(() => { 
    if (order?.files?.length > 0) {
      setProofFile(order.files.filter((f) => f.isProofSent))
    }else setProofFile([])
  }, [order]);

  const formatter = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const handleApproveFile = () => {
    setLoading(true);
    const data = {
      id: order.id,
      shop: order.shop,
      name: proofFile[proofFile.length - 1].name,
      link: proofFile[proofFile.length-1].link,
      file: {
        "files.$.customerAccepted": true,
        "files.$.customerAcceptedManually": true,
        "files.$.customerRejected": false,
        "files.$.customerAcceptedDate": new Date().toISOString(),
      },
      user: user.artist.name,
      email: user.artist.email
    }
    api.post("/orderDetailsFileUpdate", data).then((result) => {
      if(result.data.id)
        setOrderList(result.data);
      setLoading(false);
    }).catch((err) => setLoading(false));
  }

  const handleRejectFile = () => {
    
    const data = {
      id: order.id,
      shop: order.shop,
      name: proofFile[proofFile.length-1].name,
      file: {
        "files.$.customerRejected": true,
        "files.$.customerAcceptedManually": true,
        "files.$.customerAccepted": false,
        "files.$.customerRejectedDate": new Date().toISOString(),
      }
    }
    api.post("/orderDetailsFileUpdate", data).then((result) => {
      if(result.data.id)
        setOrderList(result.data);
    }); 
  }

  const handleEmailSend = () => {
    console.log("Email");
    const html = `
      <p>A proof file has been uploaded and is awaiting your approval for order ${order.id}.</p>
      <p>Click <a href="https://app.approvedshopifyapp.com/app/order?id=${order.id}&shop=${order.shop}">here</a> to view the order.</p>
    `;
  
    const data = {
      html: html,
      subject: "Proof Awaiting Approval Reminder",
      to: order.customer.email||order.contact_email,
    };
  
    handleUpdateOrderSendEmail(data);
  };
  
  const handleMsgSend = () => {
    const message = `
    <p>A proof file has been uploaded and is awaiting your approval for order ${order.id}.</p>
    <p>Click <a href="https://app.approvedshopifyapp.com/app/order?id=${id}&shop=${shop}/${order.id}">here</a> to view the order.</p>
  `;
  
    const data = {
      message: message,
      to:`${order.customer.phone||order.customer.default_address.phone}`,
    };
  
    handleUpdateOrderSendMsg(data);
  };
  

  const handleUpdateOrderSendEmail = (data) => {
    api.post("/orderDetailsSendEmail",data).then((result) => {
      console.log(result.data);
    });
  }
  const handleUpdateOrderSendMsg = (data) => {
    api.post("/orderDetailsSendMessage",data).then((result) => {
      console.log(result.data);
    });
  }

  const handleDropZoneDrop = 
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
       if(acceptedFiles.length) {
 
         let file = acceptedFiles[0];
 
         // Make new FileReader
         let reader = new FileReader();
 
         // Convert the file to base64 text
         reader.readAsDataURL(file);
 
         // on reader load somthing...
         reader.onload = () => {
 
           // Make a fileInfo Object
           let fileInfo = {
             name: file.name,
             type: file.type,
             size: Math.round(file.size / 1000) + ' kB',
             base64: reader.result,
             file: file,
             user: user.artist.name,
            email: user.artist.email
           };
           const data = {
            id: orderList.id,
            shop: orderList.shop,
            file: fileInfo 
           }
           console.log(data)
           
           api.post("/orderDetailsUploadNewProof", data).then((result) => {
             if(result.data.id)
             console.log(result.data.id);
           });
           
         }
       }
     };

  return (
    <Box width="100%">
    <div className="banner banner-waiting">
        <span className="icon-waiting-wrapper"><Icon size="small" tone="info" source={InfoIcon}/></span>
        <div className="banner-body">
          <Text variant="headingLg" as="h3" tone="subdued" fontWeight="bold">Waiting for customer</Text>
          <div className="banner-body-list">
          <Text tone="subdued" variant="headingMd" as="p" fontWeight="regular" > {`You've sent a proof ${order.customer.last_name && order.customer.first_name ? `to  ${order.customer.first_name} ${order.customer.last_name}`: order.customer.first_name? `to  ${order.customer.first_name}`: order.customer.last_name? `to  ${order.customer.last_name}`: ""   } on ${formatter.format(new Date(order.current_status_date))}`}</Text>
          <Text tone="subdued" variant="headingMd" as="p" fontWeight="regular" > You can manually approve or reject the proof (e.g. if the customer emailed or called you)</Text>
          </div>
          <div className="banner-action">
            <Grid columns={{ xs: 2, sm: 12, md: 10, lg: 20, xl: 20 }} style={{ marginTop: "10px", width: "100%" }}>
            <Grid.Cell columnSpan={{ xs: 1, sm: 6, md: 5, lg:4, xl: 4 }}>
                <div onClick={handleApproveFile} className="btn approved-btn" style={{}}>Approve</div>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 1, sm: 6, md: 5, lg: 4, xl: 4 }}>
                <div onClick={toggleActive} className="btn reject-btn" style={{}}>Reject</div>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 2, sm: 6, md: 9, lg: 6, xl: 6 }}>
              <div onClick={handleEmailSend} className="btn" style={{width:"100%"}}>Resend Reminder Email</div>
              </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 2, sm: 6, md: 9, lg: 6, xl: 6 }}>
              <div onClick={handleMsgSend} className="btn" style={{width:"100%"}}>Resend Reminder SMS</div>
              </Grid.Cell>
            </Grid>
        </div>
        </div>
        
      </div>  
      <Modal
          size="small"
          open={active}
          onClose={toggleActive}
        title="Reject Proof"
        
          primaryAction={{
            content: 'Reject',            
            onAction: handleRejectFile,
            icon: XCircleIcon,            
          }}
          secondaryActions={{
            content: 'Cancel',
            onAction: toggleActive
                  
          }}
        >
        <Modal.Section>
          <div style={{ padding: "0px 0px", display: "inline-grid", textAlign: "left", gap: "10px", width: "100%", boxShadow: "0 2px 1px rgba(170, 170, 170, 0.25)" }}>
          <DropZone   onDrop={handleDropZoneDrop} variableHeight>
            <div style={{width:"100%"}}><Button  icon={AttachmentFilledIcon} tone="success" variant="primary">Add internal file(s)</Button></div>
    
              </DropZone>
        <span>Your comments (optional)</span>
        <TextField  placeholder="Add a comment..."
                value={comment}
                onChange={setComment}
                multiline={3}
                autoComplete="off" />
        
      </div>
        </Modal.Section>
      </Modal>
    </Box>         
  );
}
            
