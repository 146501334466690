import {
  Box,
  Text,
  Icon,
  Badge,
  Button,
  Modal,
} from "@shopify/polaris";
import { useState, useCallback, useEffect, useRef } from 'react';
import "./styles.customorder.css";
import { UploadIcon, XCircleIcon, ViewIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import ModalExample from "./pdf";

export default function OrderDetailProofSent({ id, shop, files, setOrderList }) {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(true);
  const [openFileDailog, setOpenFileDailog] = useState(false);
  const [comment, setComment] = useState("");
  const [proofFile, setProofFile] = useState([]);
  const [file, setFile] = useState({});
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);
  
 

  
  
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  
  




 

  useEffect(() => { 
    if (files?.length > 0) {
      setProofFile(files.filter((f) => f.isProofSent))
    }else setProofFile([])
  }, [files]);

  
  const handleViewFile = (file) => {
    setFile(file);
    
    setActive(true);
  }

  
    const handleDeleteFile = (name) => {
      const data = {
        id, shop,
        name: name 
      }
      api.post("/orderDetailsDeleteInternalFile", data).then((result) => {
        if(result.data.id)
          setOrderList(result.data);
      });
    }
  
  const handleFileSendToProof = () => {
    console.log("file", file.link);
      const data = {
        id, shop,link:file?.link
      }
      console.log(data)
      if (data.id && data.shop && data.link) {
        api.post("/orderDetailsFileAddToProof", data).then((result) => {
          if(result.data.id)
            setOrderList(result.data);
        });
      }
    }

    function formatDate(dateString) {
      const options = {
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      
      const date = new Date(dateString);
      return date.toLocaleString('en-US', options);
    }

  return (<>
   
            <div style={{ gap: "20px"}}>
      {proofFile?.map((data, index) => 
        <div style={{ margin: "0px 0px 20px" }}>
            <Box id="sent-proof-box" as="section" >
          <div className="column-header">
            <Text as="h6" tone="subdued" fontWeight="semibold" >
            Proof #{index+1}
            </Text>
                  {/* {data.isProofAccepted && <Badge tone="success">Approved</Badge>}
                    {data.isProofRejected && <Badge tone="critical">Rejected</Badge>} */}
              
              </div> 
          <div className="column-body">
                
                <div className="pdf-container-proof-sent" >
                <div style={{height:"85%", display:"flex", justifyContent:"space-between", padding: "5px"}}>
                <span style={{color:"white", backgroundColor:"#7f170b", height:"fit-content", padding:"2px 5px", borderRadius:"3px", fontSize:"10px", textTransform:"uppercase"}}>{data.type.substring(data.type.lastIndexOf("/") + 1)}</span>
                <a style={{transform: "scaleY(-1)", height:"fit-content", color:"#FFFFFF"}} href={data.link}> <span ><Icon tone="inherit" source={UploadIcon} /></span></a>
                </div>
                <div style={{padding:"15px 5px 5px", overflow: "hidden",
    textOverflow: "ellipsis", color: "#ffffff",  backgroundImage: "linear-gradient(to bottom, #eb2310, #470b01)", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px"}}>
                    <span style={{whiteSpace: "nowrap", maxWidth:"100%", 
                      overflow: "hidden",textOverflow: "ellipsis"}}>{data.name}</span>    
                  <div style={{ display: "flex",justifyContent:"space-between", alignItems:"center", padding:"5px 0px" }}>
                    <p>Upload date {formatDate(data.sentDate)} </p>
                    <div onClick={() => handleViewFile(data)}  style={{padding:"2px 10px", minWidth:"60px", fontSize:"12px"}} className="btn">View</div>
                   
                  </div>
                </div>
                </div>  
              </div>   
                  {data.customerComment && <div className="column-footer">
                    <Text as="h3" tone="subdued" fontWeight="semibold" >
                      Customer comment
                    </Text>
                    <Text as="p" tone="subdued" fontWeight="normal" >
                      {data.customerComment}
                    </Text>
                    
          </div>}
          {(data.comment && !data.customerComment)  && <div className="column-footer">
                    <Text as="h3" tone="subdued" fontWeight="semibold" >
                      Comment
                    </Text>
                    <Text as="p" tone="subdued" fontWeight="normal" >
                      {data.comment}
                    </Text>
                    <div style={{ display: 'flex', gap: "30px" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                      <Button tone="success" variant="primary"><button style={{ color: "#FFFFFF", background: "transparent", border: "none", width: "fit-content", padding: "12px 30px", borderRadius: "7px", fontSize: "14px", fontWeight: "500" }}>Approve Proof</button> </Button>
                      <span className="reject"><Button tone="critical" variant="primary"><button style={{ color: "#FFFFFF", background: "#eb2310", border: "none", width: "fit-content", padding: "12px 35px", borderRadius: "7px", fontSize: "14px", fontWeight: "500" }}>Reject Proof</button> </Button></span>
                    </div>
                  </div>}
                </Box>
                </div>
      )}
      </div>
      <Modal
          size="fullScreen"
          open={active}
          onClose={toggleActive}
          title={file.name}
        >
      <Modal.Section>
      {file?.type?.includes('image') ? <img src={file.link} width={"100%"} /> :
          <ModalExample fileUrl={file.link} />}
      </Modal.Section>
      <Modal.Section>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
          <div onClick={() => toggleActive()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn">Close</div>
      </div>
      </Modal.Section>
      </Modal>
    </>
  );
}
            
