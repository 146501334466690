import {
  Box,
  Page,
  Text,
  Icon,
  Grid,
  Button,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import {   ChevronDownIcon,CaretUpIcon, CaretDownIcon, AttachmentFilledIcon, CheckSmallIcon, OrderFilledIcon, ChatIcon } from "@shopify/polaris-icons";
import api from "../axiosClient";
import "./styles.customorder.css";
import ModalExample from "./pdf";
import ItemsDetail from "./ItemsDetail";


export default function OrderStatusTracking({orderList, setOrderList}) {
  function formatDate(dateString) {
    const options = {
      day: '2-digit',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

  const [lastHistory, setLastHistory] = useState({});

  useEffect(() => {
    if (orderList && orderList.history && orderList.history.length > 0) {
      setLastHistory(orderList.history[orderList.history.length - 1]);
    } else {
      setLastHistory({});
    }
  }, [orderList]);

  

  console.log(orderList);
  
  
  return (
    <Page fullWidth>
      
        <div className="order-status-wrapper">
        <div class="timeline-wrapper">
          <div class="order-detail-wrapper">
            { <div className="order-status">{lastHistory?.status ? lastHistory.status : "Status Not Set"  } </div>}
            <div className="order-number">Order {orderList.name }</div>
            <div className="estimate-time">Please check the timeline below for the status of your order.</div>
            {orderList.shipping_date && <div className="estimate-time">Estimated Completion Date: <b className="estimate-time-b">{new Date(orderList.shipping_date).toLocaleDateString()} </b></div>}
                        </div>
        <ul class="StepProgress">
        {lastHistory ? orderList.history?.map((order,index) => <li class={`StepProgress-item is-done`}> 
          <div class="StepProgress-item-status">{order.status}</div>
          <div className="StepProgress-item-comment">{order.comment}</div>
          <div className="StepProgress-item-time">{formatDate(order.date)}</div>
        </li>) : <>not set</>}
          </ul>
          <Button onClick={() => setOrderList({})} className="custom-button-search-again">Search Again</Button>
        </div>
        </div>
      
      {/* {orderList?.files?.filter((f) => f.customerComment).length > 0 && <Grid>
        <Grid.Cell columnSpan={{ xs: 10, sm: 10, md: 9, lg: 8, xl: 8 }}>
          <div
            style={{
              borderRadius: "8px",
              border: "1px solid #DEE2E6",
              overflow: "hidden",
              background: "#FFFFFF",
              margin: "30px 0",
              width: "100%",
              padding: "20px"
            }}
          >
            <Text as="p" variant="headingLg" fontWeight="bold">Comments</Text>
            {orderList?.files?.filter((f) => f.customerComment).map((item) => <div style={{ alignItems: 'center', marginTop: "10px" }}>
              <Text as="p" variant="headingSm" fontWeight="bold">{formatDate(item.customerAcceptedDate || item.customerRejectedDate)}</Text>
              <div style={{ display: "flex", alignItems: 'center', marginTop: "10px" }}><span className="status-icon" style={{ backgroundColor: "transparent", marginRight: "10px" }}><Icon source={ChatIcon} /></span>
                <Text as="span" variant="headingSm" >{item.customerComment}</Text></div>
            </div>)}
          </div>
        </Grid.Cell>
      </Grid>} */}
    </Page>
  );
}

 
            
