import { useParams } from "react-router-dom";
import OrderDetail from "./components/OrderDetail";
import { useEffect, useState } from "react";
import api from "./axiosClient";
import CustomSpinner from "./components/Loader";


export default function OrderDetailWrapperAdmin() {
  let { orderId, token } = useParams();
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    api.post(`/decode`, { token: token }).then((response) => {
      if (response?.data) {
          setUser(response.data);
          localStorage.setItem('user', JSON.stringify(response.data));  
          setError(false);
      } else {
          setError(true);
          setUser({});
          localStorage.removeItem('user');  
      }
      setLoading(false);
  }).catch((error) => {
      setError(true);
      setUser({});
    localStorage.removeItem('user');
    setLoading(false);
  });
  }, []);
  return (
  <>
    {(orderId && user?.shop) && <div style={{width:"100%", minHeight:"100vh", height:"100%", paddingBottom:"30px"}}><CustomSpinner loading={loading} /><OrderDetail orderId={orderId} shop={user.shop} /></div>}
  </>
  );
}

