import axios from 'axios';

const api = axios.create({
  baseURL:'https://server.approvedshopifyapp.com',
    //'http://localhost:8001',//
  headers: {
    'Content-Type': 'application/json',
  }
});

export default api;