import React, { useEffect, useState } from 'react';
import { Card, FormLayout, Page, TextField, Button } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import "./styles.statusproform.css";
import CustomSpinner from './Loader';
import api from '../axiosClient';

export default function OrderStatusForm({setOrderList}) {
  const [email, setEmail] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState({});

  const handleEmailChange = (value) => setEmail(value);
  const handleOrderNumberChange = (value) => setOrderNumber(value);
  const handleSubmit = async () => {
      setLoading(true);
      await handleGetOrders();
      setLoading(false);

  };

    const handleGetOrders = async () => {
    setError(false);
    await api.get(`/getOrderStatus?email=${email}&name=${orderNumber}`).then((data) => {
        console.log(data.data)
        if (data?.data?.id) {
            setOrder(data.data)
            setError(false);
        } else {
            setError(true);
            setOrder({})   
        }
    });
    }
    useEffect(() => { 
        setOrderList(order);
    }, [order]);
  return (
      <Page>
          <CustomSpinner loading={loading} />
      <div className="center-form">
        <Card sectioned>
          <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>ORDER STATUS</h2>
          <FormLayout>
            <TextField
              label="Email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              placeholder="email@example.com"
            />
            <TextField
              label="Order number"
              value={orderNumber}
              onChange={handleOrderNumberChange}
              type="text"
              placeholder="37000"
            />
            <div style={{ textAlign: 'center' }}>
                          <Button className="custom-button" onClick={handleSubmit}>Find Order</Button>
                      </div>
                      {error && <div className="error-text">Order not found. <br /> Please check the email and order number.</div>}
          </FormLayout>
        </Card>
      </div>
    </Page>
  );
}
