import {
  Box,
  Text,
  Collapsible,
  Button,
  TextField,
  Avatar,
} from "@shopify/polaris";
import { useState, useEffect } from 'react';
import "./styles.customorder.css";
import { SendIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";

export default function OrderComments({ id, shop, orderComments, setOrderList, user }) {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([...orderComments]);

  function formatDate(dateString) {
    const options = {
      day: '2-digit',
      month: 'long',    
    };
    
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

  function formatTime(dateString) {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

  console.log(user)
 
  useEffect(() => {
    console.log("OrderComments",orderComments)
    setCommentList([...orderComments])
  }, []);

  const handleAddComment = () => {
    setComment("");
    setCommentList([...commentList,
      {
        text: comment,
        date: new Date(),
        name: user.artist.name
      }
    ])
    handleUpdateOrder({
      id, shop,
      comment,
      name: user.artist.name
    })
  }

  const handleUpdateOrder = (data) => {
    api.post("/updateOrderComments",data).then((result) => {
      setOrderList(result.data);
    });
  }

  function getInitials(name) {
    // Split the name into an array of words
    const words = name.split(' ');
  
    // Get the first letter of each of the first two words
    const initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');
  
    return initials;
  }

  function formatDateToLocal(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA'); // 'en-CA' gives 'YYYY-MM-DD' format
}
  
  const groupedComments = commentList.reduce((acc, comment) => {
    const localDate = formatDateToLocal(comment.date);
    if (!acc[localDate]) {
        acc[localDate] = [];
    }
    acc[localDate].push(comment);
    return acc
}, {});

console.log(Object.values(groupedComments))

  return (
    <>
    <Text as="h6" tone="subdued" fontWeight="semibold" >
              Comments
              </Text> 
      <Box id="comment-box" >
        <div className="comment-column-footer">
          <Avatar size="xl" initials={getInitials(user.artist.name)} name="Woluwayemisi Weun-Jung" />
          <div style={{width:"100%"}}>
          <TextField
                placeholder="Leave a comment..."
                value={comment}
                onChange={setComment}
                multiline={1}
              autoComplete="off"
              id="text-box"
            />
          </div>
        </div>
        
      </Box>  
      <Box id="comment-post" >
      <div style={{float:"right", marginTop:"5px"}}><div onClick={handleAddComment}  style={{padding:"4px 10px", minWidth:"60px"}} className="btn">Post</div></div>
      </Box>

      <ul class="StepProgress-Comment">
        {Object.values(groupedComments).map((com, index) =>
          <>
            <div style={{ marginTop: index > 0 ? "10px" : "70px", paddingLeft:"10px" }} class="StepProgress-item-status">{formatDate(com[0].date)}</div>
         {com.map((com) => <li  class={`StepProgress-Comment-item is-done`}>
            
              <div className="StepProgress-item-container">
                <div className="StepProgress-item-comment">{com.text}{<div>({com.name})</div>}</div>
                <div className="StepProgress-item-time">{formatTime(com.date)}</div>
              </div>
         </li>)}
         </>
        )}
{/*         
          <li style={{marginTop: "0px"}}class={`StepProgress-Comment-item is-done`}> 
          <div class="StepProgress-item-status">{"order.status"}`</div>
          <div className="StepProgress-item-comment">`{"order.comment"}`</div>
          <div className="StepProgress-item-time">`{"formatDate(order.date)"}`</div>
        </li> */}
        
          </ul>
            {/* <div className="right-column-body bottom-padding-remove">
            
            <div class="scrollable-view">

          { commentList.map((com) => <><div id="comment-1">
                <div style={{display:"flex", justifyContent:"space-between", alignItems: "center", gap:"5px", marginTop:"5px"}}>
                    <Text as="h4" tone="subdued" fontWeight="bold" >
                    {com.name}
                </Text> 
                <Text as="span" tone="subdued"  >
                    {formatDate(com.date)}
                  </Text> 
                  </div>
              </div>
              <Text as="span" tone="subdued"  >
                    {com.text}
          </Text></>)}
          
              </div>
              
            </div> */}
            
      
       
      </>
  );
}
            
