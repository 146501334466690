import {
  Box,
  Text,
  Icon,
  Modal,
  Badge,
  Button,
  DropZone,
  Grid,
  Collapsible,
  Select,
  LegacyCard,
  DataTable,
  ProgressBar,
} from "@shopify/polaris";
import { useState, useCallback, useEffect, useRef } from 'react';
import "./styles.customorder.css";
import { PlusIcon, ViewIcon, UploadIcon, DeleteIcon, AttachmentFilledIcon, XCircleIcon,StatusActiveIcon,AlertDiamondIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import ModalExample from "./pdf";

export default function OrderInternalFile({ setLoading, id, shop, files, setOrderList, user }) {
  const [active, setActive] = useState(false);
  const [activeMsg, setActiveMsg] = useState({active:false, title: "", msg: ""});
  const [open, setOpen] = useState(true);
  const [internalFile, setInternalfile] = useState([]);
  const [file, setFile] = useState({});
  const [progress, setProgress] = useState(0);
  const [apiCallComplete, setApiCallComplete] = useState(false);
  // Create a reference to the hidden file input element
  
  
 

  const handleSendtoProofClicked = useCallback(async (data) => {
    await handleFileSendToProof(data);
  }, []);
  
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleActiveMsg = useCallback((title = "", msg = "") => {
    setActiveMsg(prevActiveMsg => ({
        active: !prevActiveMsg.active,
        title: title,
        msg: msg
    }));
}, []);
  useEffect(() => { 
    if (files?.length > 0) {
      setInternalfile(files.filter((f) => f.isInternalFile))
    }else setInternalfile([])
  }, [files]);

  
  const handleViewFile = (file) => {
    setFile(file);
    setActive(true);
  }

  const handleDropZoneDrop = 
     (_dropFiles, acceptedFiles, _rejectedFiles) => {
       if (acceptedFiles.length) {
         setLoading(true);
        setProgress(0);
        let file = acceptedFiles[0];

        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = async () => {

          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + ' kB',
            base64: reader.result,
            file: file,
            user: user.artist.name,
            email: user.artist.email
          };
          const data = {
            id, shop,
            file: fileInfo 
          }
          console.log(data);

          const interval = setInterval(() => {
            setProgress(prev => {
              if (prev < 80) {
                return prev + 5; // 80% over 30 seconds, update every second
              }
              clearInterval(interval);
              return prev;
            });
          }, 1000);
          
          await api.post("/orderDetailsUploadNewInternalFile",data).then((result) => {
            if(result.data.id)
              setOrderList(result.data);
              clearInterval(interval);
            setProgress(100);
            setApiCallComplete(true);
            setActiveMsg({ active: true, type: "success", msg: "File Uploaded to Internal Files Successfully" });
            setLoading(false);
          }).catch((err) => {
            clearInterval(interval);
            setProgress(100);
            setApiCallComplete(true);
            setLoading(false);
            setActiveMsg({ active: true, type: "reject", msg: "File Uploaded Failed" });
          }
          )
        }
      }
    };
  
  useEffect(() => {
      if (apiCallComplete) {
        setProgress(100);
      }
    }, [apiCallComplete]);
  
  const handleDeleteFile = (name) => {
      setLoading(true);
      const data = {
        id, shop,
        name: name 
      }
    api.post("/orderDetailsDeleteInternalFile", data).then((result) => {
      if (result.data.id)
        setOrderList(result.data);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    })
    }
  
  const handleFileSendToProof = (file) => {
    setLoading(true);
    console.log("file", file.link);
      const data = {
        id,
        shop,
        link: file?.link,
        user: user.artist.name,
        email: user.artist.email
      }
      console.log(data)
      if (data.id && data.shop && data.link) {
        api.post("/orderDetailsFileAddToProof", data).then((result) => {
          if(result.data.id)
            setOrderList(result.data);
            toggleActive();
            setLoading(false);
            setActiveMsg({active:true,type:"success", msg:"File Added to Proofs Successfully"});
        }).catch(error => {
          setActiveMsg({ active: true, type: "reject", msg: "File Added to Proofs Failed" })
          setLoading(false);
        });
        
      }
    }

    function formatDate(dateString) {
      const options = {
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      
      const date = new Date(dateString);
      return date.toLocaleString('en-US', options);
    }
  
    const transformSpellCheck = (file) => {
      return file.spellCheck?.flatMap((item) => 
        item.corrections?.filter((corr) => corr.text.length > 1).map((corr) => [
          corr.text,
          corr.best_candidate,
          <Select options={corr.candidates} />
        ])
      ) || [];
    };
  
  console.log("internalFile",activeMsg );

  return (<>
    <Box id="internal-file-box">
          <div className="column-header">
            <Text as="h6" tone="subdued" fontWeight="semibold" >
          Internal File <Badge>{internalFile.length}</Badge>
              </Text>  
              
            </div> 
            <Collapsible
              open={open}
              id="basic-collapsible"
              transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
              expandOnPrint
                >
            <div className="right-column-body">
            
            <DropZone   onDrop={handleDropZoneDrop} variableHeight>
            <div style={{ width: "100%" }}>
            <div style={{padding:"4px 20px"}} className="btn">Add internal files</div>
              {/* <Button icon={AttachmentFilledIcon} tone="success" variant="primary">Add internal file(s)</Button> */}
            </div>
            {progress > 0 && <span style={{width: 225}}>
              <ProgressBar progress={progress} size="small" />
            </span>}
              </DropZone>
              <div style={{marginTop:"10px"}}>
              {/* <Grid columns={{xs:1 , sm: 2}}>
              {internalFile?.map((data) => <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
            
            <div style={{ width: "100%", aspectRatio: "16/6", backgroundColor: "#eb2310", borderRadius: "5px",backgroundSize:"cover", backgroundImage: "none" }}>
            <div style={{height:"85%", display:"flex", justifyContent:"space-between", padding: "5px"}}>
                  <span style={{color:"white", backgroundColor:"#7f170b", height:"fit-content", padding:"2px 5px", borderRadius:"3px", fontSize:"10px", textTransform:"uppercase"}}>{data.type.substring(data.type.lastIndexOf("/") + 1)}</span>
                  <span  style={{height:"fit-content",display:"flex", color:"#FFFFFF"}}><a style={{transform: "scaleY(-1)", height:"fit-content", color:"#FFFFFF"}} href={data.link}> <span ><Icon tone="inherit" source={UploadIcon} /></span></a> <span onClick={() => handleDeleteFile(data.name)}> <Icon tone="inherit" source={DeleteIcon} /></span></span>
              </div>
              <div style={{padding:"15px 5px 5px", overflow: "hidden",textOverflow: "ellipsis", color: "#ffffff", backgroundSize:"cover", backgroundImage: "linear-gradient(to bottom, #eb2310, #470b01)", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", fontSize:"10px"}}>
                  <span style={{whiteSpace: "nowrap", maxWidth:"100%", 
                      overflow: "hidden", textOverflow: "ellipsis"
                    }}>{data.name}</span>  
                    <div style={{ display:"flex", justifyContent:"space-between", alignItems:"center", padding:"5px"}}>
                    <span><span>{data.size}</span><Text as="p">Upload date: {formatDate(data.internalFileUploadDate)} </Text></span>
                      <Button icon={ViewIcon} onClick={() => handleViewFile(data)} variant="primary" tone="success">View</Button>                      
                    </div>
              </div>
              </div>    
                  </Grid.Cell>)}
            </Grid> */}
            <Grid columns={{xs:3 , sm: 2}}>
              {internalFile.map((data) => <>
            <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
                <div style={{ width: "100%", aspectRatio: "16/6", backgroundColor: "#eb2310", borderRadius: "5px",backgroundSize:"cover", backgroundImage: "none" }}>
              <div style={{height:"85%", display:"flex", justifyContent:"space-between", padding: "5px"}}>
                  <span style={{color:"white", backgroundColor:"rgb(127, 23, 11)", height:"fit-content", padding:"2px 5px", borderRadius:"3px", fontSize:"10px", textTransform:"uppercase"}}>{data.type.substring(data.type.lastIndexOf("/") + 1)}</span>
                  <span  style={{height:"fit-content",display:"flex", color:"#FFFFFF"}}><a style={{transform: "scaleY(-1)", height:"fit-content", color:"#FFFFFF"}} href={data.link}> <span ><Icon tone="inherit" source={UploadIcon} /></span></a> <span onClick={() => handleDeleteFile(data.name)}> <Icon tone="inherit" source={DeleteIcon} /></span></span>
              </div>
              <div style={{padding:"15px 5px 5px", overflow: "hidden", textOverflow: "ellipsis", color: "#ffffff", backgroundSize:"cover", backgroundImage: "linear-gradient(to bottom, #eb2310, #470b01)", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", fontSize:"10px"}}>
                  <span style={{whiteSpace: "nowrap", maxWidth:"100%", 
                    overflow: "hidden",textOverflow: "ellipsis"}}>{data.name}</span>    
                  <p>{data.size}</p>
              </div>
              </div>    
              </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                      <div style={{
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
                    height: "100%",
                    minHeight: "60px",
                    alignItems: "flex-start"
}}>
                      
                    <Text as="p">{data.user ? `${data.user} uploaded this on` :`Uploaded date`} {formatDate(data.internalFileUploadDate)} </Text>
                                          
                    <div onClick={() => handleViewFile(data)}  style={{padding:"4px 10px", minWidth:"60px"}} className="btn">View</div>
                    </div>
                    
              </Grid.Cell>
              </>
              )}
              </Grid>
              </div>
        </div> 
        </Collapsible>
            </Box>
        <Modal
          size="fullScreen"
          open={active}
          onClose={toggleActive}
          title={file.name}
      
          // primaryAction={file.isProofAdded ?{
          //   content: 'Add to Proofs',
          //   onAction: () => handleSendtoProofClicked(file),
          //   icon: PlusIcon,            
          // }:null}
      
          //  secondaryActions={{
          //   content: 'View Spell Check',
          //    onAction: toggleActiveSpellCheck,  
            
          // }}
        >
      <Modal.Section>
        {file?.type?.includes('image') ? <div style={{width: "100%", height: "100%", position: "relative", overflow: "hidden" }}><img src={file.link} style={{ width:"100%", height:"100%", objectFit:"cover"}} /> </div>:
          <ModalExample fileUrl={file.link} />}
        {/* {file.text && <div style={{display:"inline-grid", gap:"8px"}}>
          <Text as="p" variant="headingMd" tone="subdued">PDF text content</Text>
          <Text variant="bodyMd" tone="subdued">{file.text}</Text>
          <Text as="p" variant="headingMd" tone="subdued">Spell Check and Suggestions</Text>
          <div>
            <LegacyCard>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Word',
            'Best Match',
            'Suggestions',
          ]}
                rows={transformSpellCheck(file)}
        />
      </LegacyCard>

          </div>
        </div>
        } */}
        
      </Modal.Section>
      <Modal.Section>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
          <div onClick={() => handleSendtoProofClicked(file)} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn">Add to Proofs</div>
      </div>
      </Modal.Section>
    </Modal>
    
    <Modal
      size="small"
      open={activeMsg.active}
      onClose={toggleActiveMsg}
      title={activeMsg.type === 'success' ? "Success" : "Error"}
      secondaryActions={{
        content: 'Close',
        onAction: toggleActiveMsg,
      }}
    >
      <Modal.Section>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {activeMsg.type === 'success' ? (
            <>
              <span style={{width:"40px"}}><Icon source={StatusActiveIcon} tone="success" /></span>
              <span >{activeMsg.msg}</span>
            </>
          ) : (
            <>
              <span style={{width:"40px"}}><Icon source={AlertDiamondIcon}  tone="critical"/></span>
              <span style={{ marginLeft: 8 }}>{activeMsg.msg}</span>
            </>
          )}
        </div>
      </Modal.Section>
    </Modal>
    </>
  );
}
            
