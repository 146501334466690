import { AppProvider, Badge, DataTable, Page, Text } from '@shopify/polaris';
import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import "./styles.customorder.css";

function getBadge(status) {
    const badges = {
      "Awaiting Shipment": (
        <div className="badge awaiting-shipment">
          <div className="icon"></div>
          Awaiting Shipment
        </div>
      ),
      "Awaiting Proof": (
        <div className="badge awaiting-proof">
          <div className="icon"></div>
          Awaiting Proof
        </div>
      ),
      "Proof Sent": (
        <div className="badge proof-sent">
          <div className="icon"></div>
          Proof Sent
        </div>
      ),
      "Proof Rejected": (
        <div className="badge proof-rejected">
          <div className="icon"></div>
          Proof Rejected
        </div>
      ),
      "Proof Accepted": (
        <div className="badge proof-accepted">
          <div className="icon"></div>
          Proof Accepted
        </div>
      ),
      "Backorder": (
        <div className="badge backorder">
          <div className="icon"></div>
          Backorder
        </div>
      ),
      "default": (
        <div className="badge default">
          <div className="icon"></div>
          Default Badge
        </div>
      ),
    };
  
    return badges[status] || badges["default"];
  }

    
function OrderHistoryTable({ history, handleDeleteOrderHistory, id, shop }) {
    

    const rows = history.map(event => [
      <span>{getBadge(event.status)}</span>,
      <Text variation="subdued">{formatDistanceToNow(new Date(event.date), { addSuffix: true })}</Text>,
      <Text>{event.comment}</Text>,
        <span style={{ textDecoration: "underline", color: "#6366F1", cursor: "pointer" }} onClick={() => handleDeleteOrderHistory({id:id, shop:shop, statusToDelete:event.status})}>Delete</span>
    ]);

  return (
    <DataTable
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text',
      ]}
      headings={[
        'Event',
        'When',
        'Comment',
        '',
      ]}
      rows={rows}
    />
  );
}

const history = [
  { status: "Awaiting Proof", when: "1 day ago", comment: "Set from tag", email: null },
  { status: "Proof Accepted", when: "2 days ago", comment: "Set from tag", email: null },
  // Add more events as needed
];

export default function OrderHistoryTableComp({ order, handleDeleteOrderHistory }) {
    console.log(order)
    
  return (
        <OrderHistoryTable history={order.history} id={order.id} shop={order.shop} handleDeleteOrderHistory={handleDeleteOrderHistory} />
  );
}
